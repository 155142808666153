import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PDF = () => {
  return <iframe src="../data/evidence/0935165_Janessa_Kritische_Literatuurstudie" width="100%" height="500px" />
}

export default PDF
